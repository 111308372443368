import type React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'

import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES } from '@cms/utils/constants'
import type { DocumentsGalleryProps } from '@knauf-group/ct-designs/components/core/DocumentsGallery'
import { DocumentsGallery } from '@knauf-group/ct-designs/components/core/DocumentsGallery'
import type { DocumentTileProps } from '@knauf-group/ct-designs/components/core/DocumentTile'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'
import type {
  TypeContentDamDocumentSkeleton,
  TypeContentDocumentGallerySkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import { useDownloadEvent } from '@knauf-group/ct-shared-nextjs/web/hooks/useWebAnalytics'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

const formatDocumentsEntry = ({
  sys,
  fields: document,
}: ContentEntry<TypeContentDamDocumentSkeleton>): DocumentTileProps => {
  const documentReference = document?.documentReference?.[0]
  const thumbnail = {
    url: FALLBACK_IMAGES.DOCUMENTS,
    title: '',
  } as ImageWeb

  return {
    id: sys.id,
    headline: document?.headline || '',
    description: document?.description || '',
    thumbnail,
    nextImageComponent: Image,
    size: documentReference?.size || 0,
    type: documentReference?.type || '',
    url: documentReference?.url || '#',
  }
}

const DocumentsGalleryWrapper: React.FC<ContentEntry<TypeContentDocumentGallerySkeleton>> = ({
  fields: documentsGalleryContent,
}) => {
  const { push: pushDownloadEvent } = useDownloadEvent()
  const { normalizeReference } = useNormalizeReference()

  const handleDownloadClick = (downloadClickEvent: DocumentTileProps & { action: string }) => {
    pushDownloadEvent({
      assetId: downloadClickEvent.id as string,
      assetName: downloadClickEvent.headline,
      exportFormat: downloadClickEvent.type,
      target: downloadClickEvent.url,
      action: downloadClickEvent.action,
    })
  }

  if (!documentsGalleryContent) {
    return null
  }

  const reference = normalizeReference({
    reference: documentsGalleryContent?.reference,
  })

  const documentsGalleryProps: DocumentsGalleryProps = {
    nextLinkComponent: NextLink,
    headline: documentsGalleryContent?.headline,
    text: documentsGalleryContent?.text,
    buttonProps: reference,
    documents: documentsGalleryContent?.documents
      ?.filter((document) => document.fields) // Filter out draft content from contentful in published mode. Preview mode still includes drafts.
      .map((document) => formatDocumentsEntry(document)),
  }
  return <DocumentsGallery {...documentsGalleryProps} onClick={handleDownloadClick} />
}

export default DocumentsGalleryWrapper
